import React, { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { Spinner } from "react-bootstrap";

const StyledFormLabel = styled(Form.Label)`
  font-size: 18px;
  font-weight: 500;
  color: #271344;
`;

const StyledFormControl = styled(Form.Control)`
  font-size: 18px;
`;

const AccountInfo = ({ onSubmit, initialValues, isLoading }) => {
  const { register, handleSubmit, formState, watch, errors } = useForm({ mode: "onChange" });
  const password = useRef({});
  password.current = watch("password", "");

  const handlePreSubmit = async info => {
    onSubmit(info);
  };
  return (
    <Form onSubmit={handleSubmit(handlePreSubmit)}>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Email address</b>
        </StyledFormLabel>
        <StyledFormControl
          type="email"
          defaultValue={initialValues.email || ''}
          name="email"
          readOnly
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Update Password</b>
        </StyledFormLabel>
        <StyledFormControl
          type="password"
          ref={register({ required: true })}
          name="password"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Confirm Password</b>
        </StyledFormLabel>
        <StyledFormControl
          type="password"
          ref={register({
            required: true,
            validate: value =>
              value === password.current || "The passwords do not match"
          })}
          name="confirm_password"
        />
        {errors.confirm_password && <Form.Text className="text-danger">{errors.confirm_password.message}</Form.Text>}
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Name</b>
        </StyledFormLabel>
        <StyledFormControl type="text" defaultValue={initialValues.name || ''} ref={register({ required: true, value: initialValues.name })} name="name" />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Child's name</b>
        </StyledFormLabel>
        <StyledFormControl
          type="text"
          defaultValue={initialValues.childName || ''}
          ref={register({ required: true, value: initialValues.childName })}
          name="childName"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <StyledFormLabel>
          <b>Child's birthday</b>
        </StyledFormLabel>
        <StyledFormControl
          defaultValue={initialValues.childBirthday || ''}
          ref={register({ required: true, value: initialValues.childBirthday })}
          type="date"
          name="childBirthday"
        />
        <Form.Text>Findings will only be provided for toddlers between the ages of 12-36 months at this time. Our lesson plans are designed for toddlers (children 12-36 months old), but can be accessed regardless of your child’s age.</Form.Text>
      </Form.Group>
      <div className="text-center mt-5">
        <Button
          variant="warning"
          size="lg"
          type="submit"
          className="px-4"
          disabled={!formState.isValid || isLoading}
        >
          Update Account Info {isLoading && <Spinner animation="border" as="span" animation="border" size="sm" />}
        </Button>
      </div>
    </Form>
  );
};

export default AccountInfo;
