import axios from "axios";
import { apiUrl } from "../constants/api";

export const checkout = async checkoutInfo => {
  const { data } = await axios.post(`${apiUrl}/stripe/checkout`, checkoutInfo, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};

export const cancelSubscription = async cancelSubscriptionInfo => {
  const { data } = await axios.post(`${apiUrl}/stripe/cancel-subscription`, cancelSubscriptionInfo, {
    headers: {
      "x-access-token": localStorage.getItem("token"),
    },
  });
  return data.data;
};