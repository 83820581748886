import React from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Loading = ({
  className
}) => {
  return (
    <Container className={className}>
      <Spinner animation="border" />
    </Container>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
