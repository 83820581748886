import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import Loading from "../components/Loading";
import { StaticImage } from "gatsby-plugin-image";
import { me, updateMe } from "../api/users";
import { cancelSubscription } from "../api/stripe";
import styled from "styled-components";
import Layout from "../components/layout";
import AccountInfo from "../components/AccountInfo";

const WidgetSection = styled.div`
  width: 100%;
  margin: auto;
  text-align: center;
`;

const Account = () => {
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [displayCancelButton, setDisplayCancelButton] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [isCancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false);
  const [isCancelSuccess, setCancelSuccess] = useState(false);
  const [isAccountInfoLoading, setAccountInfoLoading] = useState(false);
  const [isAccountInfoSuccess, setAccountInfoSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      const { subscriptionId: newSubscriptionId, name, email, childName, childBirthday, isSubscriptionActive, isSubscriptionCanceled } = await me();
      setInitialValues({
        name,
        email,
        childName,
        childBirthday
      })
      setSubscriptionId(newSubscriptionId);
      setDisplayCancelButton(isSubscriptionActive && !isSubscriptionCanceled ? true : false);
      setLoading(false);
    } catch (err) { }
  }, []);

  const handleCancelMembership = async () => {
    try {
      setCancelSubscriptionLoading(true);
      await cancelSubscription({ subscriptionId });
      setCancelSuccess(true);
      setTimeout(() => {
        window.location.href = '/pricing/';
      }, 3000);
    } catch (err) { }
  }

  const handleSubmitAccountInfo = async accountInfo => {
    try {
      setAccountInfoLoading(true);
      await updateMe(accountInfo);
      setAccountInfoLoading(false);
      setAccountInfoSuccess(true);
      setTimeout(() => {
        setAccountInfoSuccess(false);
      }, 3000);
    } catch (err) { }
  }

  return isLoading ? <Loading /> : (
    <Layout>
      <div id="content" className="site-content">
        <div className="content-inner">
          <div className="">
            <div className="row content-row">
              <div id="primary" className="content-area col-12">
                <main id="main" className="site-main">
                  <article
                    id="post-45"
                    className="post-45 page type-page status-publish hentry"
                  >
                    <div className="entry-content clearfix">
                      <div
                        data-elementor-type="wp-page"
                        data-elementor-id="45"
                        className="elementor elementor-45"
                        data-elementor-settings="[]"
                      >
                        <div className="elementor-section-wrap">
                          <section
                            className="elementor-section elementor-top-section elementor-element elementor-element-f6893e0 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                            data-id="f6893e0"
                            data-element_type="section"
                            data-settings='{"stretch_section":"section-stretched"}'
                          >
                            <div className="elementor-container elementor-column-gap-default ">
                              <div
                                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-9f5f77a"
                                data-id="9f5f77a"
                                data-element_type="column"
                              >
                                <div className="elementor-widget-wrap elementor-element-populated">
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-4522805 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="4522805"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-367b21f"
                                        data-id="367b21f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-9265126 cms-element elementor-widget elementor-widget-cms_image_single"
                                            data-id="9265126"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-left-to-right ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width={133}
                                                    height={137}
                                                    src="../images/image-home-2.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8869f2a"
                                        data-id="8869f2a"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                            data-id="8832270"
                                            data-element_type="widget"
                                            data-widget_type="cms_heading.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">

                                                <h3 className="custom-heading">
                                                  <span>
                                                    Account Settings
                                                  </span>
                                                </h3>
                                                <div className="custom-heading-description">
                                                  Update your information below:{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                          <div
                                            className="elementor-element elementor-element-6f927b2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="6f927b2"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single animation-none wow flash">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="99"
                                                    height="23"
                                                    src="../images/image-bee.png"
                                                    className="attachment-full size-full"
                                                    alt="Kindori"
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-6be929f"
                                        data-id="6be929f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-97fbc33 cms-animation-bottom-to-top2 elementor-widget elementor-widget-cms_image_single"
                                            data-id="97fbc33"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width="180"
                                                    height="186"
                                                    src="../images/image-home-3.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <section
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-74d00c8 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                                    data-id="74d00c8"
                                    data-element_type="section"
                                  >

                                    <WidgetSection>
                                      <Container>
                                        <Row className="justify-content-center">
                                          <Col md="5">
                                            {isAccountInfoSuccess && (
                                              <Alert variant="success">
                                                <div>Your account is successfully updated!</div>
                                              </Alert>
                                            )}
                                            <AccountInfo isLoading={isAccountInfoLoading} initialValues={initialValues} onSubmit={handleSubmitAccountInfo} />
                                          </Col>
                                        </Row>
                                      </Container>
                                    </WidgetSection>
                                    {
                                      displayCancelButton
                                        ? <Container>
                                          <Row className="justify-content-center">
                                            <Col md="8">
                                              <div
                                                className="elementor-element elementor-element-8832270 elementor-widget elementor-widget-cms_heading"
                                                data-id="8832270"
                                                data-element_type="widget"
                                                data-widget_type="cms_heading.default"
                                                style={{ paddingTop: '110px' }}
                                              >
                                                <div className="elementor-widget-container">
                                                  <div className="cms-heading-wrapper cms-heading-layout1 wow pulse">
                                                    <h3 className="custom-heading">
                                                      <span>
                                                        Cancel Membership
                                                      </span>
                                                    </h3>
                                                    {isCancelSuccess && (
                                                      <Alert variant="success">
                                                        <div>Your membership subscription is successfully cancelled!</div>
                                                        <div>
                                                          This will redirect back to pricing after 3 seconds.
                                                        </div>
                                                      </Alert>
                                                    )}
                                                    <div className="custom-heading-description">
                                                      Update your membership settings below:{" "}
                                                    </div>
                                                  </div>{" "}
                                                </div>
                                              </div>
                                              <div className="text-center mt-5">
                                                <Button
                                                  variant="warning"
                                                  size="lg"
                                                  type="submit"
                                                  className="px-4"
                                                  disabled={isCancelSuccess || isCancelSubscriptionLoading}
                                                  onClick={handleCancelMembership}
                                                >
                                                  Click here to cancel your membership. {isCancelSubscriptionLoading && <Spinner animation="border" as="span" animation="border" size="sm" />}
                                                </Button>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Container>
                                        : null
                                    }
                                  </section>
                                  <div
                                    className="elementor-section elementor-inner-section elementor-element elementor-element-b410ac4 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                                    data-id="b410ac4"
                                    data-element_type="section"
                                  >
                                    <div className="elementor-container elementor-column-gap-default ">
                                      <div
                                        className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-0b4fe1f"
                                        data-id="0b4fe1f"
                                        data-element_type="column"
                                      >
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                          <div
                                            className="elementor-element elementor-element-1bae692 cms-animation-bottom-to-top elementor-widget elementor-widget-cms_image_single"
                                            data-id="1bae692"
                                            data-element_type="widget"
                                            data-widget_type="cms_image_single.default"
                                          >
                                            <div className="elementor-widget-container">
                                              <div className="cms-image-single cms-animation-bottom-to-top2 ">
                                                <div className="cms-img-box">
                                                  <StaticImage
                                                    placeholder="none"
                                                    width={132}
                                                    height={116}
                                                    src="../images/image-home-1.png"
                                                    className="attachment-full size-full"
                                                    alt=""
                                                    loading="lazy"
                                                  />{" "}
                                                </div>
                                              </div>{" "}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
